/* eslint-disable no-console */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from "axios";
import { getAccessToken, APP_URL, getApiAccessToken } from "../configs/config";

const constructApiUrl = ({ positionId, selectedOption1, selectedOption2 }) => {
    const baseApiUrl = `${APP_URL}/tally/position`;
    const params = { positionId };
    params.locationType = selectedOption1.value;

    try {
        if (selectedOption1.value !== "national" && selectedOption2) {
            params.locationId = selectedOption2?.id;
        }

        if (selectedOption1.value !== "national" && !selectedOption2?.id) {
            return null;
        }

        const queryString = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");

        return `${baseApiUrl}?${queryString}`;
    } catch (err) {
        console.log("Error", err);

        return null;
    }
};

const searchLocations = async (searchText) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/location/search`, {
            params: {
                name: searchText,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        return [];
    }
};

const searchNames = async (searchText) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    const searchTextPayloadToBeAdded = searchText ? { name: searchText } : null;

    try {
        const response = await axios.get(`${APP_URL}/candidate/getByName`, {
            params: {
                limit: 5000,
                ...searchTextPayloadToBeAdded,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        return [];
    }
};

// This is used to get the default opponent of candidate 1
const searchNamesCandidate2 = async ({ candidatePayload = "", name = "" }) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    const candidatePayloadToBeAdded = candidatePayload
        ? { candidateId1: candidatePayload }
        : null;

    const nameSearchToBeAdded = name ? { name } : null;

    try {
        const response = await axios.get(
            `${APP_URL}/analysis/compare/opponents`,
            {
                params: {
                    limit: 5000,
                    ...candidatePayloadToBeAdded,
                    ...nameSearchToBeAdded,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-auth-token": authToken,
                },
            },
        );
        const { data } = response;

        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        return [];
    }
};

const nationalData = async () => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/position/national`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

const fetchAllPositions = async ({
    positionId,
    selectedOption1,
    selectedOption2,
}) => {
    const apiUrl = constructApiUrl({
        positionId,
        selectedOption1,
        selectedOption2: selectedOption2 || {},
    });
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        return response.data;
    } catch (error) {
        console.error(`Error fetching data: ${error.message}`);
        throw error;
    }
};

const fetchAllRegions = async ({ locationId, type }) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();
    let params = { regionId: locationId || null };

    if (type === "district") {
        params = { districtId: locationId || null };
    }

    if (type === "province") {
        params = { provinceId: locationId || null };
    }

    if (type === "municipality") {
        params = { municipalityId: locationId || null };
    }

    try {
        const response = await axios.get(`${APP_URL}/region`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        const regions = data.map((region) => {
            const label = `${region.name}`;

            return {
                id: region.id,
                value: region.id,
                label,
            };
        });

        return regions;
    } catch (error) {
        return error;
    }
};

const fetchAllProvince = async ({ locationId, type }) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();
    let params = { provinceId: locationId };

    if (type === "district") {
        params = { districtId: locationId };
    }

    if (type === "municipality") {
        params = { municipalityId: locationId };
    }

    try {
        const response = await axios.get(`${APP_URL}/province`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        const provinces = data.map((province) => {
            return {
                id: province.id,
                value: province.name,
                label: province.name,
                region_id: province.regionID,
            };
        });

        return provinces;
    } catch (error) {
        return error;
    }
};

const fetchAllMunicipality = async () => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/municipality`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        const municipalities = data.map((municipality) => {
            return {
                id: municipality.id,
                value: municipality.name,
                label: municipality.name,
            };
        });

        return municipalities;
    } catch (error) {
        return error;
    }
};

const fetchAllDistrict = async ({ locationId, type }) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        let params = { province: locationId || null };

        if (type === "district") {
            params = { district: locationId || null };
        }

        if (type === "municipality") {
            params = { municipality: locationId || null };
        }

        const response = await axios.get(`${APP_URL}/district`, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        const districts = data.map((district) => {
            return {
                id: district.id,
                value: district.name,
                label: district.name,
            };
        });

        return districts;
    } catch (error) {
        return error;
    }
};

// const fetchCandidate = async (scopetype, candidate, overall) => {
//     const token = getAccessToken();

//     try {
//         const response = await axios.get(`${APP_URL}/tally/candidate`, {
//             params: {
//                 scopetype,
//                 candidate,
//                 overall,
//             },
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         const { data } = response;
//         console.log(data, "show data for candidate")
//         return data;
//     } catch (error) {
//         console.error(error);

//         return null;
//     }
// };
const fetchCandidate = async (
    scopetype,
    candidate,
    overall,
    withBarangay = false,
    municipalityId = null,
    barangayId = null,
) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        let apiUrl = `${APP_URL}/tally/candidate`;

        // Add parameters for barangay search
        if (withBarangay && municipalityId) {
            apiUrl += `?scopetype=${scopetype}&candidate=${candidate}&overall=${overall}&withBarangay=1&municipalityId=${municipalityId}`;
        } else if (!withBarangay) {
            apiUrl += `?scopetype=${scopetype}&candidate=${candidate}&overall=${overall}`;
        }

        // Add paramters for precinct search
        if (barangayId) {
            apiUrl += `&barangayId=${barangayId}`;
        }

        const response = await axios.get(apiUrl, {
            params: {
                limit: 50000,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return null;
    }
};

// const fetchLocationData = async (scopetype, dropdown2) => {
//     const TOKEN = getAccessToken();
//     let url = `${APP_URL}/tally/location`;

//     if (scopetype !== 1 && dropdown2 !== undefined) {
//         url += `?scope=${dropdown2}&scopeType=${scopetype}`;
//     }

//     try {
//         const response = await axios.get(url, {
//             headers: {
//                 Authorization: `Bearer ${TOKEN}`,
//             },
//         });

//         return response.data;
//     } catch (error) {
//         console.error(error);
//         throw error;
//     }
// };
const fetchLocationData = async (scopetype, dropdown2) => {
    const TOKEN = getAccessToken();
    const authToken = getApiAccessToken();
    let url;

    if (scopetype === 1) {
        url = `${APP_URL}/tally/location`;
    } else if (scopetype !== 1 && dropdown2 !== undefined) {
        url = `${APP_URL}/tally/location?scope=${dropdown2}&scopeType=${scopetype}`;
    }

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
                "x-auth-token": authToken,
            },
        });
        const { data } = response;

        return data;
        // const filteredData = response.data.filter((item, index) => index !== 3);
        // console.log(filteredData, "response for location no index 3")
        // return filteredData;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchNumberOfVoters = async (
    searchType,
    selectedOption1,
    selectedOption2,
    candidate,
    positionId,
    regionId,
    selectedOption3 = null,
) => {
    const TOKEN = getAccessToken();
    const authToken = getApiAccessToken();
    const BASE_URL = `${APP_URL}/tally/summary`;
    let url = `${BASE_URL}?searchType=${searchType}&type=${selectedOption1}`;

    if (searchType === "name") {
        url += `&id=${candidate}`;

        if (selectedOption3) {
            url += `&barangayId=${selectedOption3}`;
        }
    } else if (searchType === "location" && !selectedOption2) {
        url += `&id=${regionId}`;
    } else if (searchType === "location" && selectedOption2) {
        url += `&id=${selectedOption2}`;
    } else if (searchType === "position") {
        if (selectedOption2) {
            url += `&id=${selectedOption2}&positionId=${positionId}`;
        }

        if (!selectedOption2) {
            url += `&positionId=${positionId}`;
        }

        if (selectedOption1 !== "national" && !selectedOption2) {
            return null;
        }
    } else {
        return null;
    }

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
                "x-auth-token": authToken,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export {
    searchLocations,
    nationalData,
    searchNames,
    searchNamesCandidate2,
    fetchAllRegions,
    fetchAllProvince,
    fetchAllMunicipality,
    fetchAllDistrict,
    fetchAllPositions,
    fetchCandidate,
    fetchLocationData,
    fetchNumberOfVoters,
};
