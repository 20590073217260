import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Modal,
    Fade,
    Backdrop,
} from "@mui/material";

const ProfileChangePassword = () => {
    const [shouldReload, setShouldReload] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setModalMessage("New password and confirm password do not match.");
            setModalOpen(true);

            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, oldPassword, newPassword);
            setModalMessage("Password changed successfully!");
            setShouldReload(true);
            setModalOpen(true);
        } catch (error) {
            if (
                error.message.toLowerCase() ===
                "incorrect username or password."
            ) {
                setModalMessage(
                    "Error changing password: Incorrect old password",
                );
            } else {
                setModalMessage(`Error changing password: ${error.message}`);
            }
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);

        if (shouldReload) {
            window.location.reload();
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 13 }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Typography component="h1" variant="h5">
                    {"Change Password"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="oldPassword"
                        label="Old Password"
                        type="password"
                        id="oldPassword"
                        autoComplete="current-password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {"Change Password"}
                    </Button>
                </Box>
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 1,
                        }}
                    >
                        <Typography variant="h6" component="h2">
                            {modalMessage}
                        </Typography>
                        <Button
                            onClick={handleCloseModal}
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                        >
                            {"Close"}
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default ProfileChangePassword;
