import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
    barangays,
    clusteredPrecincts,
    districts,
    municipalitiesDistrict,
    precincts,
    provinces,
    regions,
} from "../../api/locationApi";
import FullScreenSpinner from "../../components/customs/fullscreenspinner";
import { APP_URL } from "../../configs/config";

const DownloadVotersList = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const [regionsAvailable, setRegionsAvailable] = useState([]);
    const [provincesAvailable, setProvincesAvailable] = useState([]);
    const [districtsAvailable, setDistrictsAvailable] = useState([]);
    const [municipalitiesAvailable, setMunicipalitiesAvailable] = useState([]);
    const [barangaysAvailable, setBarangaysAvailable] = useState([]);
    const [precinctsAvailable, setPrecinctsAvailable] = useState([]);

    const [requestFor, setRequestFor] = useState("");
    const [dataPer, setDataPer] = useState("");
    const [dataPerHidden, setDataPerHidden] = useState(false);
    const [areaType, setAreaType] = useState("");
    const [downloadDisabled, setDownloadDisabled] = useState(true);

    const [region, setRegion] = useState({});
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [municipality, setMunicipality] = useState({});
    const [barangay, setBarangay] = useState({});
    const [clusteredPrecinct, setClusteredPrecinct] = useState({});

    const resetAllInputsFrom = (from) => {
        switch (from) {
            case "dataPer":
                setDataPer("");

            case "areaType":
                setAreaType("");

            case "region":
                setRegion({});

            case "province":
                setProvince({});

            case "district":
                setDistrict({});

            case "municipality":
                setMunicipality({});

            case "barangay":
                setBarangay({});

            case "clusteredPrecinct":
                setClusteredPrecinct({});
            default:
                return null;
        }
    };

    const delay = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), ms);
        });
    };

    // Fetch Regions
    useEffect(() => {
        const fetchRegions = async () => {
            setLoading(true);
            await delay(500);
            const regionData = await regions();
            setRegionsAvailable(regionData);
            setLoading(false);
        };
        fetchRegions();
    }, []);

    // Fetch Provinces
    useEffect(() => {
        const fetchProvinces = async () => {
            if (region.id) {
                setLoading(true);
                await delay(500);
                const provinceData = await provinces(region.id);
                setProvincesAvailable(provinceData);
                setLoading(false);
            }
        };
        fetchProvinces();
    }, [region]);

    // Fetch Districts
    useEffect(() => {
        const fetchDistricts = async () => {
            if (region.id) {
                setLoading(true);
                await delay(500);
                const districtsData = await districts(region.id);
                setDistrictsAvailable(districtsData);
                setLoading(false);
            }
        };
        fetchDistricts();
    }, [region]);

    // Fetch Municipalities
    useEffect(() => {
        const fetchMunicipalities = async () => {
            if (province.id || district.id) {
                setLoading(true);
                await delay(500);

                const municipalitiesData = await municipalitiesDistrict(
                    province.id,
                    district.id,
                );
                setMunicipalitiesAvailable(municipalitiesData);
                setLoading(false);
            }
        };
        fetchMunicipalities();
    }, [province, district]);

    // Fetch Barangay
    useEffect(() => {
        const fetchBarangays = async () => {
            if (municipality.id) {
                setLoading(true);
                await delay(500);

                const barangaysData = await barangays({
                    municipalityId: municipality.id,
                });
                setBarangaysAvailable(barangaysData);
                setLoading(false);
            }
        };
        fetchBarangays();
    }, [municipality]);

    // Fetch Barangay
    useEffect(() => {
        const fetchPrecincts = async () => {
            if (barangay.id) {
                setLoading(true);
                await delay(500);

                const precinctsData = await clusteredPrecincts({
                    barangayId: barangay.id,
                });
                setPrecinctsAvailable(precinctsData);
                setLoading(false);
            }
        };
        fetchPrecincts();
    }, [barangay]);

    // Identify to Allow Download or Not
    useEffect(() => {
        if (areaType === "Region" && Object.keys(region).length !== 0) {
            setDownloadDisabled(false);

            return;
        }

        if (areaType === "Province" && Object.keys(province).length !== 0) {
            setDownloadDisabled(false);

            return;
        }

        if (areaType === "District" && Object.keys(district).length !== 0) {
            setDownloadDisabled(false);

            return;
        }

        if (
            areaType === "Municipality" &&
            Object.keys(municipality).length !== 0
        ) {
            setDownloadDisabled(false);

            return;
        }

        if (areaType === "Barangay" && Object.keys(barangay).length !== 0) {
            setDownloadDisabled(false);

            return;
        }

        if (
            areaType === "Clustered-Precinct" &&
            Object.keys(clusteredPrecinct).length !== 0
        ) {
            setDownloadDisabled(false);

            return;
        }
        setDownloadDisabled(true);
    }, [region, province, district, municipality, barangay, clusteredPrecinct]);

    const handleDownload = async () => {
        if (downloadDisabled) {
            setSnackbarMessage(
                "You cannot download until all data required are filled.",
            );
            setSnackbarOpen(true);
        }
        setLoading(true);
        await delay(500);

        // Instead of using the endpoint, we force download since blobbing gets the data corrupted from the API
        const form = document.createElement("form");
        form.method = "GET";
        form.action = `${APP_URL}/download/excel`;
        form.target = "_blank";

        const inputs = {
            requestFor,
            dataPer,
            areaType,
            regionId: region.id,
            provinceId: province.id,
            districtId: district.id,
            municipalityId: municipality.id,
            barangayId: barangay.id,
            clusteredPrecinctId: clusteredPrecinct.id,
        };

        const queryString = new URLSearchParams(inputs).toString();
        const downloadUrl = `${APP_URL}/download/excel?${queryString}`;
        // Log the full URL with query parameters
        console.log("zzzz Download URL:", downloadUrl);

        Object.entries(inputs).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== "") {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }
        });

        document.body.appendChild(form);
        form.submit();

        document.body.removeChild(form);

        setLoading(false);
    };

    const renderAreaType = () => {
        if (dataPer === "Province") {
            return [
                <MenuItem key="Region" value="Region">
                    {"REGIONAL"}
                </MenuItem>,
                <MenuItem key="Province" value="Province">
                    {"PROVINCIAL"}
                </MenuItem>,
            ];
        }

        if (dataPer === "District") {
            return [
                <MenuItem key="Province" value="Province">
                    {"PROVINCIAL"}
                </MenuItem>,
                <MenuItem key="District" value="District">
                    {"DISTRICT"}
                </MenuItem>,
            ];
        }

        if (dataPer === "Municipality") {
            return [
                <MenuItem key="Province" value="Province">
                    {"PROVINCIAL"}
                </MenuItem>,
                <MenuItem key="Municipality" value="Municipality">
                    {"MUNICIPALITY"}
                </MenuItem>,
            ];
        }

        if (dataPer === "Clustered-Precinct") {
            return [
                <MenuItem key="Municipality" value="Municipality">
                    {"MUNICIPALITY"}
                </MenuItem>,
                <MenuItem key="Barangay" value="Barangay">
                    {"BARANGAY"}
                </MenuItem>,
                <MenuItem key="Clustered-Precinct" value="Clustered-Precinct">
                    {"CLUSTERED-PRECINCT"}
                </MenuItem>,
            ];
        }

        if (dataPer === "" && requestFor === "Voters List") {
            return [
                <MenuItem key="Barangay" value="Barangay">
                    {"BARANGAY"}
                </MenuItem>,
                <MenuItem key="Clustered-Precinct" value="Clustered-Precinct">
                    {"CLUSTERED-PRECINCT"}
                </MenuItem>,
            ];
        }

        return [
            <MenuItem key="Region" value="Region">
                {"REGIONAL"}
            </MenuItem>,
            <MenuItem key="Province" value="Province">
                {"PROVINCIAL"}
            </MenuItem>,
            <MenuItem key="District" value="District">
                {"DISTRICT"}
            </MenuItem>,
            <MenuItem key="Municipality" value="Municipality">
                {"MUNICIPALITY"}
            </MenuItem>,
            <MenuItem key="Barangay" value="Barangay">
                {"BARANGAY"}
            </MenuItem>,
            <MenuItem key="Clustered-Precinct" value="Clustered-Precinct">
                {"CLUSTERED-PRECINCT"}
            </MenuItem>,
        ];
    };

    // Identifies which Dropdown Boxes will show up depending on Area Type
    const identifyDropdownVisibility = (inputType) => {
        if (
            inputType === "Province" &&
            (areaType === "Province" ||
                areaType === "Municipality" ||
                areaType === "Barangay" ||
                areaType === "Clustered-Precinct")
        ) {
            return true;
        }

        if (inputType === "District" && areaType === "District") {
            return true;
        }

        if (
            inputType === "Municipality" &&
            (areaType === "Municipality" ||
                areaType === "Barangay" ||
                areaType === "Clustered-Precinct")
        ) {
            return true;
        }

        if (
            inputType === "Barangay" &&
            (areaType === "Barangay" || areaType === "Clustered-Precinct")
        ) {
            return true;
        }

        if (
            inputType === "Clustered-Precinct" &&
            areaType === "Clustered-Precinct"
        ) {
            return true;
        }

        return false;
    };

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 13 }}>
            {loading && <FullScreenSpinner />}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <Typography component="h1" variant="h5" mb={3}>
                    {"Download Data"}
                </Typography>
                <Box component="form" sx={{ width: "100%" }}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{"Request For"}</InputLabel>
                        <Select
                            value={requestFor}
                            onChange={(e) => {
                                setRequestFor(e.target.value);
                                resetAllInputsFrom("dataPer");

                                if (e.target.value === "Voters List") {
                                    setDataPerHidden(true);
                                } else {
                                    setDataPerHidden(false);
                                }
                            }}
                            label="Request For"
                        >
                            <MenuItem value="Voters List">
                                {"VOTERS LIST"}
                            </MenuItem>
                            <MenuItem value="Election Result">
                                {"ELECTION RESULT"}
                            </MenuItem>
                        </Select>
                    </FormControl>

                    {!dataPerHidden && (
                        <FormControl
                            fullWidth
                            margin="normal"
                            disabled={!requestFor}
                        >
                            <InputLabel>{"Data Per / Segregate By"}</InputLabel>
                            <Select
                                value={dataPer}
                                onChange={(e) => {
                                    setDataPer(e.target.value);
                                    resetAllInputsFrom("areaType");
                                }}
                                label="Data Per / Segregate By"
                            >
                                <MenuItem key="Province" value="Province">
                                    {"PROVINCE"}
                                </MenuItem>
                                <MenuItem key="District" value="District">
                                    {"DISTRICT"}
                                </MenuItem>
                                <MenuItem
                                    key="Municipality"
                                    value="Municipality"
                                >
                                    {"MUNICIPALITY"}
                                </MenuItem>
                                <MenuItem
                                    key="Clustered-Precinct"
                                    value="Clustered-Precinct"
                                >
                                    {"CLUSTERED-PRECINCT"}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <FormControl
                        fullWidth
                        margin="normal"
                        disabled={!requestFor}
                    >
                        <InputLabel>
                            {"Area where the data will be taken"}
                        </InputLabel>
                        <Select
                            value={areaType}
                            onChange={(e) => {
                                setAreaType(e.target.value);
                                resetAllInputsFrom("region");
                            }}
                            label="Area where the data will be taken"
                        >
                            {renderAreaType().map((option) => {
                                return option;
                            })}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>{"Region"}</InputLabel>
                        <Select
                            value={region}
                            renderValue={(selected) =>
                                selected ? selected.name : ""
                            }
                            onChange={(e) => {
                                setRegion(e.target.value);
                                resetAllInputsFrom("province");
                            }}
                            label="Region"
                            disabled={!dataPerHidden && !dataPer} // Only rely on DataPer for Election Result
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        overflowY: "auto",
                                    },
                                },
                            }}
                        >
                            {regionsAvailable.map((regionItem) => (
                                <MenuItem
                                    key={regionItem.name}
                                    value={regionItem}
                                >
                                    {regionItem.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {identifyDropdownVisibility("Province") && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{"Province"}</InputLabel>
                            <Select
                                value={province}
                                renderValue={(selected) =>
                                    selected ? selected.name : ""
                                }
                                onChange={(e) => {
                                    setProvince(e.target.value);
                                    resetAllInputsFrom("district");
                                }}
                                label="Province"
                                disabled={Object.keys(region).length === 0}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        },
                                    },
                                }}
                            >
                                {provincesAvailable.map((provinceItem) => (
                                    <MenuItem
                                        key={provinceItem.name}
                                        value={provinceItem}
                                    >
                                        {provinceItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {identifyDropdownVisibility("District") && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{" District"}</InputLabel>
                            <Select
                                value={district?.name}
                                onChange={(e) => {
                                    setDistrict(e.target.value);
                                    resetAllInputsFrom("municipality");
                                }}
                                label="District"
                                disabled={Object.keys(region).length === 0}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        },
                                    },
                                }}
                            >
                                {districtsAvailable.map((districtItem) => (
                                    <MenuItem
                                        key={districtItem.name}
                                        value={districtItem}
                                    >
                                        {districtItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {identifyDropdownVisibility("Municipality") && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{"Municipality"}</InputLabel>
                            <Select
                                value={municipality?.name}
                                onChange={(e) => {
                                    setMunicipality(e.target.value);
                                    resetAllInputsFrom("barangay");
                                }}
                                label="Municipality"
                                disabled={Object.keys(province).length === 0}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        },
                                    },
                                }}
                            >
                                {municipalitiesAvailable.map(
                                    (municipalityItem) => (
                                        <MenuItem
                                            key={municipalityItem.name}
                                            value={municipalityItem}
                                        >
                                            {municipalityItem.name}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    )}

                    {identifyDropdownVisibility("Barangay") && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{"Barangay"}</InputLabel>
                            <Select
                                value={barangay?.name}
                                onChange={(e) => {
                                    setBarangay(e.target.value);
                                    resetAllInputsFrom("Clustered-Precinct");
                                }}
                                label="Barangay"
                                disabled={
                                    Object.keys(municipality).length === 0
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        },
                                    },
                                }}
                            >
                                {barangaysAvailable.map((barangayItem) => (
                                    <MenuItem
                                        key={barangayItem.name}
                                        value={barangayItem}
                                    >
                                        {barangayItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {identifyDropdownVisibility("Clustered-Precinct") && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>{"Clustered-Precinct"}</InputLabel>
                            <Select
                                value={clusteredPrecinct?.name}
                                onChange={(e) =>
                                    setClusteredPrecinct(e.target.value)
                                }
                                label="Clustered-Precinct"
                                disabled={Object.keys(barangay).length === 0}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        },
                                    },
                                }}
                            >
                                {precinctsAvailable.map((precinctItem) => (
                                    <MenuItem
                                        key={precinctItem.name}
                                        value={precinctItem}
                                    >
                                        {precinctItem.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={handleDownload}
                        disabled={downloadDisabled}
                    >
                        {"Download "}
                        {requestFor} {"Data"}
                    </Button>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Container>
    );
};

export default DownloadVotersList;
