import React, { useState, useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import {
    APP_URL,
    setAccessToken,
    setRefreshToken,
    setUserDetails,
    getAccessToken,
    setIdToken,
    setApiAccessToken,
} from "../configs/config";

const PrivateRoute = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const fetchData = async () => {
        try {
            // eslint-disable-next-line consistent-return
            await Auth.currentUserInfo().then(async (data) => {
                if (!data && getAccessToken() === null) {
                    return (window.location = "/login");
                }

                setIsLoggedIn(!isLoggedIn);
                setUserDetails(data);

                const tokenForApi = await axios.post(
                    `${APP_URL}/token/generate`,
                    { userId: data?.username },
                );
                setApiAccessToken(tokenForApi?.data?.token);
            });
        } catch (err) {
            // DO NOTHING
        }
    };

    // eslint-disable-next-line consistent-return
    const getLocalSession = async () => {
        try {
            const session = await Auth.currentSession();

            if (session) {
                setAccessToken(session?.accessToken?.jwtToken);
                setRefreshToken(session?.refreshToken?.token);
                setIdToken(session?.idToken?.jwtToken);
            }
        } catch (e) {
            console.log(e);

            if (e === "No current user") {
                localStorage.clear();

                return (window.location = "/login");
            }
        }
    };

    useEffect(() => {
        fetchData();
        getLocalSession();
    }, []);

    return <div>{isLoggedIn ? <Outlet /> : <CircularProgress />}</div>;
};

export default PrivateRoute;
