import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UndoIcon from "@mui/icons-material/Undo";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    WrapperTop,
    Backdiv,
    StyledAutocomplete,
    StyledTab,
} from "./AnalysisHeader.styled";
import { AppContext } from "../../context/AppContext";
import {
    POSITIONS_OBJECT,
    POSITIONS,
    POSITIONS_OBJECT_WINNERS,
} from "../../constants/positions";
import { searchNames, searchNamesCandidate2 } from "../../api/searchApi";

const NAV = [
    { text: "WINNABILITY ANAYLSIS", url: "/winnability-analysis" },
    { text: "COMPARISON ANAYLSIS", url: "/comparison-analysis" },
    { text: "WINNERS PERFORMANCE", url: "/winners-performance" },
    { text: "TARGET VOTES CALCULATOR", url: "/target-votes-calculator" },
];

const WINNABILITY_ANALYSIS = "/winnability-analysis";
const COMPARISON_ANAYLSIS = "/comparison-analysis";
const WINNERS_PERFORMANCE = "/winners-performance";
const TARGET_VOTES_CALCULATOR = "/target-votes-calculator";

const debounce = (fn, delay = 1000) => {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

const AnalysisHeader = ({ tabValue, handleTabChange, onSearch }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const url = useLocation();
    const navigate = useNavigate();
    const positions = POSITIONS_OBJECT;
    const positionsWinners = POSITIONS_OBJECT_WINNERS;
    const [selectedPosition, setSelectedPosition] = useState("");
    const isSmallScreen = useMediaQuery("min-width:1024px");

    // For selected candidate 1 or 2
    const [selectedCandidateValue, setSelectedCandidateValue] = useState("");
    const [selectedCandidateValue2, setSelectedCandidateValue2] = useState("");

    const handleNavigate = () => {
        navigate("/candidate-smart-analysis");
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const {
        candidate,
        setCandidate,
        candidate2,
        setCandidate2,
        position,
        setPosition,
        positionId,
        setPositionId,
        location,
        setLocation,
    } = useContext(AppContext);

    const handlePositionChangeWinnersPerformance = (newPosition) => {
        setSelectedPosition(newPosition);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value.toString().toLowerCase());
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleUrlChange = (loc) => {
        navigate(loc);
    };

    // Remove all settings when changed URL
    useEffect(() => {
        setCandidate("");
        setCandidate2("");
        setPosition("");
        setPositionId("");
        setLocation("");
    }, [url.pathname]);

    // Remove all other settings when candidate is changed
    useEffect(() => {
        if (candidate !== "") {
            setCandidate2("");
            setPosition("");
            setPositionId("");
            setLocation("");
            setSelectedCandidateValue2("");
        }
    }, [candidate]);

    const renderNav = () => {
        return NAV.map((nav) => {
            const isFocusedNav = nav.url === url.pathname;

            return isFocusedNav ? (
                <StyledTab
                    onClick={() => {
                        handleUrlChange(nav.url);
                    }}
                    label={nav.text}
                />
            ) : (
                <Tab
                    onClick={() => {
                        handleUrlChange(nav.url);
                    }}
                    label={nav.text}
                />
            );
        });
    };

    const filterSameName = (firstName, lastName) => {
        if (firstName === lastName) {
            return firstName;
        }

        return `${lastName}, ${firstName}`;
    };

    const renderCandidate = (candidateState, setCandidateState) => {
        const [options, setOptions] = useState([]);
        const [loading, setLoading] = useState(false);
        const [searchValue, setSearchValue] = useState("");

        const loadCandidateOptions = async (inputValue, callback) => {
            setLoading(true);

            try {
                const response = await searchNames(inputValue);

                const options1 = response.map((candidateItem) => ({
                    label: (
                        <div>
                            <div style={{ fontWeight: 600 }}>
                                {candidateItem.lastName.toUpperCase()}
                                {candidateItem?.nomination?.positionId !==
                                    POSITIONS.partyList &&
                                    `, ${candidateItem.firstName.toUpperCase()}`}
                            </div>
                            <span style={{ color: "green", fontSize: "14px" }}>
                                {candidateItem.location
                                    ? candidateItem.location
                                    : "National"}
                            </span>
                        </div>
                    ),
                    fullName: filterSameName(
                        candidateItem.firstName.toUpperCase(),
                        candidateItem.lastName.toUpperCase(),
                    ),
                    candidate_name:
                        candidateItem.nomination.positionId ===
                        POSITIONS.partyList
                            ? candidateItem.lastName.toUpperCase()
                            : `${candidateItem.lastName}, ${candidateItem.firstName}`,
                    id: candidateItem.id,
                    value: candidateItem.id,
                    position_id: candidateItem.nomination.positionId,
                    positionName: candidateItem.nomination.position.name,
                    location: candidateItem.location || "National",
                }));

                options1.sort((a, b) => a.position_id - b.position_id);

                callback(options1);
            } catch (error) {
                console.error("Failed to load locations", error);
            } finally {
                setLoading(false);
            }
        };

        const loadOptionsDebounced = useCallback(
            debounce((inputValue, callback) => {
                if (inputValue && inputValue.trim() !== "") {
                    loadCandidateOptions(inputValue, callback);
                } else {
                    setOptions([]);
                }
            }, 1000),
            [],
        );

        return (
            <Box
                className="candidate-options"
                sx={{
                    color: "#5ad990",
                    fontFamily: "Open Sans, sans-serif",
                    width: "100%",
                }}
            >
                <StyledAutocomplete
                    value={selectedCandidateValue}
                    labelId="candidate-label"
                    onChange={(e, newValue) => {
                        setSelectedCandidateValue(newValue);
                        setCandidateState(newValue);

                        if (newValue === null) {
                            setOptions([]);
                        }
                    }}
                    inputValue={searchValue}
                    onInputChange={(e, newInputValue) => {
                        setSearchValue(newInputValue);
                        setOptions([]);
                        loadOptionsDebounced(newInputValue, setOptions);
                    }}
                    className="candidateDropdown"
                    sx={{ fontFamily: "Open Sans, sans-serif", padding: 1 }}
                    options={options}
                    loading={loading}
                    getOptionLabel={(option) =>
                        typeof option === "string"
                            ? option
                            : option.candidate_name
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    filterOptions={(availableOptions) => availableOptions}
                    renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Candidate"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
        );
    };

    const renderCandidate2 = (
        setCandidateState,
        candidate2InputDisabled,
        candidatePayload,
    ) => {
        const [options, setOptions] = useState([]);
        const [loading, setLoading] = useState(false);
        const [searchValue, setSearchValue] = useState("");

        const candidate2Id = candidatePayload?.id;

        const loadCandidate2Options = async (
            inputValue,
            callback,
            candidateId2,
        ) => {
            setLoading(true);

            try {
                const response = !inputValue
                    ? await searchNamesCandidate2({
                          candidatePayload: candidateId2,
                      })
                    : await searchNamesCandidate2({
                          candidatePayload: candidateId2,
                          name: inputValue,
                      });

                const handleStructureResponse = (data) => {
                    return data?.map((candidateItem) => ({
                        label: (
                            <div>
                                <div style={{ fontWeight: 600 }}>
                                    {candidateItem.lastName.toUpperCase()}
                                    {candidateItem.lastName.toUpperCase() !==
                                        candidateItem.firstName.toUpperCase() &&
                                        `, ${candidateItem.firstName.toUpperCase()}`}
                                </div>
                                <span
                                    style={{ color: "green", fontSize: "14px" }}
                                >
                                    {candidateItem.location
                                        ? candidateItem.location
                                        : "Opponent"}
                                </span>
                            </div>
                        ),
                        fullName: filterSameName(
                            candidateItem.firstName.toUpperCase(),
                            candidateItem.lastName.toUpperCase(),
                        ),
                        candidate_name:
                            candidateItem?.nomination?.positionId ===
                                POSITIONS.partyList ||
                            candidateItem.lastName === candidateItem.firstName
                                ? candidateItem.lastName.toUpperCase()
                                : `${candidateItem.lastName}, ${candidateItem.firstName}`,
                        id: candidateItem.id,
                        value: candidateItem.id,
                        position_id:
                            candidateItem?.nomination?.positionId ||
                            candidateItem?.Position?.id,
                        positionName:
                            candidateItem?.nomination?.position?.name ||
                            candidateItem?.Position?.name,
                        location: candidateItem.location || "National",
                    }));
                };

                const options2 = response?.opponents
                    ? handleStructureResponse(response?.opponents)
                    : handleStructureResponse(response);

                options2.sort((a, b) => a.position_id - b.position_id);

                setOptions(options2);
                if (callback) callback(options2);
            } catch (error) {
                console.error("Failed to load candidate2 options", error);
            } finally {
                setLoading(false);
            }
        };

        const handleFocus = () => {
            loadCandidate2Options("", setOptions, candidate2Id);
        };

        if (!options) {
            loadCandidate2Options("", setOptions, candidate2Id);
        }

        const loadOptionsDebounced = useCallback(
            debounce((inputValue, callback, candidateId2) => {
                if (inputValue && inputValue.trim() !== "") {
                    loadCandidate2Options(inputValue, callback, candidateId2);
                }
            }, 1000),
            [],
        );

        return (
            <Box
                className="candidate-options"
                sx={{
                    color: "#5ad990",
                    fontFamily: "Open Sans, sans-serif",
                    width: "100%",
                }}
            >
                <StyledAutocomplete
                    disabled={candidate2InputDisabled}
                    value={selectedCandidateValue2}
                    labelId="candidate2-label"
                    onChange={(e, newValue) => {
                        setSelectedCandidateValue2(newValue);
                        setCandidateState(newValue);
                    }}
                    inputValue={searchValue}
                    onInputChange={(e, newInputValue) => {
                        setSearchValue(newInputValue);
                        setOptions([]);

                        loadOptionsDebounced(
                            newInputValue,
                            setOptions,
                            candidate2Id,
                        );
                    }}
                    onFocus={handleFocus}
                    className="candidateDropdown"
                    sx={{ fontFamily: "Open Sans, sans-serif", padding: 1 }}
                    options={options}
                    loading={loading}
                    getOptionLabel={(option) =>
                        typeof option === "string"
                            ? option
                            : option.candidate_name
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    filterOptions={(availableOptions) => availableOptions}
                    renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Candidate 2"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
        );
    };

    const renderPosition = () => {
        return (
            <Box
                className="position-options"
                sx={{
                    fontFamily: "Open Sans, sans-serif",
                    height: "100%",
                    padding: 1,
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="position-label">{"Position"}</InputLabel>
                    <Select
                        className="postionDropdown"
                        labelId="position-label"
                        label="Position"
                        id="position-select"
                        variant="outlined"
                        fullWidth
                        disabled={!candidate}
                        onChange={handlePositionChange}
                    >
                        {positions.map((positionObj, index) => {
                            return (
                                <MenuItem
                                    key={positionObj.value}
                                    value={positionObj.value}
                                >
                                    {positionObj.label.toString().toUpperCase()}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const renderPositionReturnId = () => {
        return (
            <Box
                className="position-options-winners-performance"
                sx={{
                    fontFamily: "Open Sans, sans-serif",
                    height: "100%",
                    padding: 1,
                }}
            >
                <FormControl fullWidth>
                    <InputLabel
                        id="position-label"
                        sx={{
                            fontFamily: "Open Sans, sans-serif",
                        }}
                    >
                        {"Position"}
                    </InputLabel>
                    <Select
                        className="postionDropdown"
                        labelId="position-label"
                        label="Position"
                        id="position-select-winners-performance"
                        variant="outlined"
                        fullWidth
                        value={positionId}
                        onChange={(event) => {
                            setPositionId(event.target.value);
                        }}
                        sx={{ fontFamily: "Open Sans, sans-serif" }}
                    >
                        {positions.map((positionObj, index) => {
                            return (
                                <MenuItem
                                    key={positionObj.value}
                                    value={positionObj.id}
                                >
                                    {positionObj.label.toString().toUpperCase()}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const getAllDropdownOptions = () => {
        return ["Region", "Province", "District", "Municipality"];
    };

    const getDropdownOptions = (positionValue) => {
        switch (positionValue) {
            case "president":

            case "vice-president":

            case "senator":

            case "party list":
                return ["Region", "Province", "District"];

            case "provincial governor":

            case "provincial vice-governor":
                return ["Municipality", "Province", "District"];

            case "member, house of representatives":
                return ["Municipality", "District"];

            case "mayor":

            case "vice-mayor":
                return ["Municipality", "Barangay"];
            default:
                return [];
        }
    };

    const getDropdownOptionsById = (positionIdPassed) => {
        switch (positionIdPassed) {
            case 1:

            case 2:

            case 3:

            case 4:
                return ["Region", "Province", "District"];

            case 5:

            case 6:
                return ["Municipality", "Province", "District"];

            case 7:
                return ["Municipality", "District"];

            case 11:

            case 12:
                return ["Municipality", "Barangay"];
            default:
                return [];
        }
    };

    const renderLocation = () => {
        const [selectedValue, setSelectedValue] = useState(null);
        const dropdownOptions = getDropdownOptions(position);

        const handleChange = ({ target: { value } }) => {
            setSelectedValue(value);
            setLocation(value);
        };

        return (
            <Box className="location-options-default" sx={{ padding: 1 }}>
                <FormControl fullWidth>
                    <InputLabel
                        id="location-select-label"
                        sx={{
                            fontFamily: "Open Sans, sans-serif",
                        }}
                    >
                        {"Location"}
                    </InputLabel>
                    <Select
                        labelId="location-select-label"
                        id="location-select"
                        label="Location"
                        value={selectedValue || ""}
                        onChange={handleChange}
                        disabled={!candidate}
                        sx={{ fontFamily: "Open Sans, sans-serif" }}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const renderLocationUsingPositionId = ({
        shouldBeDisabled = false,
        positionValue = null,
    }) => {
        const [selectedValue, setSelectedValue] = useState(null);

        let dropdownOptions = [];

        if (positionValue) {
            dropdownOptions = getDropdownOptions(positionValue);
        } else if (positionId) {
            dropdownOptions = getDropdownOptionsById(positionId);
        }

        const isDropdownDisabled =
            shouldBeDisabled || (!positionValue && !positionId);

        return (
            <Box
                className="location-options-winners-performance"
                sx={{ padding: 1 }}
            >
                <FormControl fullWidth>
                    <InputLabel
                        id="location-select-label"
                        sx={{
                            fontFamily: "Open Sans, sans-serif",
                        }}
                    >
                        {"Location"}
                    </InputLabel>
                    <Select
                        disabled={isDropdownDisabled}
                        labelId="location-select-label"
                        id="location-select"
                        label="Location"
                        sx={{ fontFamily: "Open Sans, sans-serif" }}
                        variant="outlined"
                        value={selectedValue || ""}
                        onChange={(e) => {
                            setSelectedValue(e.target.value);
                            setLocation(e.target.value.toLocaleLowerCase());
                        }}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    };

    const renderFilters = () => {
        if (url.pathname === WINNABILITY_ANALYSIS) {
            return (
                <>
                    {renderCandidate(candidate, setCandidate)}
                    {renderPositionReturnId()}
                    {renderLocationUsingPositionId({
                        positionValue: selectedPosition,
                    })}
                </>
            );
        }

        if (url.pathname === COMPARISON_ANAYLSIS) {
            const candidate2InputDisabled = !candidate;
            const locationInputDisabled = !(candidate && candidate2);

            return (
                <>
                    {renderCandidate(candidate, setCandidate)}
                    {renderCandidate2(
                        setCandidate2,
                        candidate2InputDisabled,
                        candidate,
                    )}
                    {renderLocationUsingPositionId({
                        positionValue:
                            candidate?.positionName?.toLocaleLowerCase(),
                        shouldBeDisabled: locationInputDisabled,
                    })}
                </>
            );
        }

        if (url.pathname === WINNERS_PERFORMANCE) {
            return (
                <>
                    {renderPositionReturnId({
                        onSelectPosition:
                            handlePositionChangeWinnersPerformance,
                    })}
                    {renderLocationUsingPositionId({
                        positionValue: selectedPosition,
                    })}
                </>
            );
        }

        if (url.pathname === TARGET_VOTES_CALCULATOR) {
            return (
                <>
                    {renderCandidate(candidate, setCandidate)}
                    {renderPositionReturnId()}
                    {renderLocationUsingPositionId({
                        positionValue: selectedPosition,
                    })}
                </>
            );
        }

        return null;
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    centered
                    sx={(theme) => ({
                        fontFamily: "Open Sans, sans-serif",

                        [theme.breakpoints.down("md")]: {
                            minWidth: "40rem",
                        },

                        [theme.breakpoints.up("lg")]: {
                            minWidth: "90rem",
                        },
                        [theme.breakpoints.up("xl")]: {
                            minWidth: "105rem",
                        },
                    })}
                >
                    {renderNav()}
                </Tabs>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",

                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        width: "100%",
                    }}
                >
                    <Backdiv>
                        <IconButton
                            color="inherit"
                            edge="end"
                            sx={{
                                display: "flex",
                                marginLeft: "auto",
                                minWidth: isSmallScreen ? 1024 : "100%",
                            }}
                            onClick={handleNavigate}
                            className="searchButton"
                        >
                            <UndoIcon
                                sx={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    alignItems: "flex-start",
                                }}
                            />
                            {"Back"}
                        </IconButton>
                    </Backdiv>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "2rem",
                    }}
                >
                    <WrapperTop
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {renderFilters()}
                    </WrapperTop>
                    <IconButton
                        className="searchButton"
                        sx={{
                            marginLeft: "auto",
                            height: "60px",
                            width: "60px",
                            borderRadius: "1px",
                            background: "#46815f !important",
                        }}
                        onClick={onSearch}
                    >
                        <SearchTwoToneIcon
                            className="searchIcon"
                            sx={{
                                fontSize: "35px",
                                background: "#46815f !important",
                                color: "white !important",
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default AnalysisHeader;
