/* eslint-disable no-console */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from "axios";
import { getAccessToken, APP_URL, getApiAccessToken } from "../configs/config";

const getPresignedPostData = async (fileName) => {
    const token = getAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/presigned`, {
            params: { fileName },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching presigned POST data:", error);
        throw error;
    }
};

const postFileToS3 = async (file, presignedPostData) => {
    const formData = new FormData();
    const { url, fields } = presignedPostData;

    Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
    });

    formData.append("file", file);

    try {
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to upload file: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error("Error uploading file to S3:", error);
        return null;
    }
};

export const uploadPhoto = async ({ fileName, file, fileDirectory }) => {
    try {
        const presignedPostData = await getPresignedPostData(fileName);

        if (!presignedPostData) {
            throw new Error("Failed to get presigned POST data");
        }

        const formData = new FormData();
        const { url, fields } = presignedPostData;

        Object.keys(fields).forEach((key) => {
            formData.append(key, fields[key]);
        });

        formData.append("file", file);

        const response = await fetch(url, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Failed to upload file: ${response.statusText}`);
        }

        return { Location: url.split("?")[0] };
    } catch (error) {
        console.error("Error uploading file to S3:", error);
        throw error; // Propagate the error
    }
};

export { getPresignedPostData, postFileToS3 };
