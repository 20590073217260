import styled from "styled-components";
import background from "../img/background.png";

const BackgroundContainer = styled.div`
    background-image: url(${background});
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
`;

const LogoContainer = styled.div`
    // padding: 2%;
    // height: 10rem;
    // padding-top: 20px;
    // padding-left: 20px;
`;

const ImageContainerDashboard = styled.img`
    height: auto;
    object-fit: cover;
`;

const ImageContainerLogin = styled.img`
    width: 20%;
    height: auto;
    object-fit: cover;
    padding-top: 20px;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

export {
    BackgroundContainer,
    LogoContainer,
    ImageContainerDashboard,
    ImageContainerLogin,
};
