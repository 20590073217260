import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {
    NotFoundContainer,
    StyledParagraph,
    StyledTitle,
    IconWrapper,
} from "./NotFound.styled";

const NotFound = () => {
    return (
        <NotFoundContainer>
            <StyledTitle>
                <IconWrapper>
                    <ErrorOutlineIcon fontSize="inherit" />
                </IconWrapper>
                {"404\r"}
            </StyledTitle>
            <StyledParagraph>
                {"The page you are trying to access isn"}&apos;
                {"t accessible\r"}
            </StyledParagraph>
        </NotFoundContainer>
    );
};

export default NotFound;
