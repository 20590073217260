import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { history } from "../history";
import AppLayout from "../components/layouts/AppLayout";
import PrivateRoute from "./PrivateRoutes";
import DrawerAppBar from "../components/layout/navbar/Navbar";
import ComparisonAnalysis from "../views/analysis-comparison/ComparisonAnalysis";
import WinnersPerformance from "../views/analysis-winners-performance";
import TargetVotesCalculator from "../views/analysis-target-votes-calculator";
import UserAdd from "../views/user-add";
import ProfileChangePassword from "../views/profile-change-password";
import ProfileEdit from "../views/profile-edit";
import UserEdit from "../views/user-edit";
import VotersList from "../views/voters-list";
import DownloadVotersList from "../views/download-voters-list";

const Login = lazy(() => import("../views/authentication/Login"));
const Dashboard = lazy(() => import("../views/dashboard"));
const CandidateSmartAnalysis = lazy(() => import("../views/analysis-home"));
const Maps = lazy(() => import("../views/maps"));
const SearchResult = lazy(() => import("../views/search-result"));
const NotFound = lazy(() => import("../views/not-found"));
const WinnabilityAnalysis = lazy(() => import("../views/analysis-winnability"));

const AppRouter = () => {
    return (
        <Router history={history}>
            <Suspense fallback={<CircularProgress />}>
                <Routes>
                    <Route element={<AppLayout onDashboard={false} />}>
                        <Route path="/login" element={<Login />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                        <Route element={<AppLayout onDashboard />}>
                            <Route path="/" element={<Dashboard />} />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/search-result/position/:positionId"
                                element={<SearchResult />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/search-result/name/:data/:post_id/:id/:location/:positionName"
                                element={<SearchResult />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/search-result/name/:data/:post_id/:id/:positionName"
                                element={<SearchResult />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/voters-list"
                                element={<VotersList />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/candidate-smart-analysis"
                                element={<CandidateSmartAnalysis />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/winnability-analysis"
                                element={<WinnabilityAnalysis />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/comparison-analysis"
                                element={<ComparisonAnalysis />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/winners-performance"
                                element={<WinnersPerformance />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/target-votes-calculator"
                                element={<TargetVotesCalculator />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route path="/add-user" element={<UserAdd />} />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/search-result/location/:region_id"
                                element={<SearchResult />}
                            />
                            <Route path="/maps" element={<Maps />} />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/change-password"
                                element={<ProfileChangePassword />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/profile-edit"
                                element={<ProfileEdit />}
                            />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route path="/user-edit" element={<UserEdit />} />
                        </Route>
                        <Route element={<AppLayout onDashboard />}>
                            <Route
                                path="/download-voters-list"
                                element={<DownloadVotersList />}
                            />
                        </Route>
                    </Route>
                    <Route element={<AppLayout onDashboard={false} />}>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
