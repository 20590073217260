import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import AppLogoIcon from "../../assets/img/logo/logo-icon.png";
import AppLogo from "../../assets/img/logo/logo.png";
import {
    BackgroundContainer,
    LogoContainer,
    ImageContainerDashboard,
    ImageContainerLogin,
} from "../../assets/styles/AppLayout.styled";
import DrawerAppBar from "../layout/navbar/Navbar";

const AppLayout = ({ children, onDashboard = true }) => {
    return (
        <BackgroundContainer>
            {onDashboard ? <DrawerAppBar /> : null}
            <NavLink to="/">
                {onDashboard ? // <ImageContainerDashboard
                //     src={AppLogoIcon}
                //     alt="Eleksyon App Logo"
                // />
                null : (
                    <ImageContainerLogin
                        src={AppLogo}
                        alt="Eleksyon App Logo"
                    />
                )}
            </NavLink>
            {children}
            <Outlet />
        </BackgroundContainer>
    );
};

export default AppLayout;
