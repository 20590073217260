// THIS FILE IS FOR MANAGING USERS IN AWS

import {
    AdminCreateUserCommand,
    AdminUpdateUserAttributesCommand,
    AdminDeleteUserCommand,
    CognitoIdentityProviderClient,
    ListUsersCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import awsconfig from "../configs/aws-config";

const client = new CognitoIdentityProviderClient({
    region: awsconfig.aws_cognito_region,
    credentials: {
        accessKeyId: awsconfig.aws_access_key_id,
        secretAccessKey: awsconfig.aws_secret_access_key,
    },
});

export async function updateUserAttributes(username, attributes) {
    const command = new AdminUpdateUserAttributesCommand({
        UserPoolId: awsconfig.aws_user_pools_id,
        Username: username,
        UserAttributes: Object.keys(attributes).map((key) => ({
            Name: key,
            Value: attributes[key],
        })),
    });

    const response = await client.send(command);

    return response;
}

export async function getUserDetailsByEmail(email) {
    const command = new ListUsersCommand({
        UserPoolId: awsconfig.aws_user_pools_id,
        Filter: `email = "${email}"`,
    });

    try {
        const response = await client.send(command);
        const users = response.Users;

        if (users.length > 0) {
            return users[0];
        }
    } catch (error) {
        console.error("Error getting user details:", error);

        return null;
    }

    return null;
}

export async function createUser(
    email,
    name,
    temporaryPassword = "!newUserPassword!00",
    additionalParams = {},
) {
    const command = new AdminCreateUserCommand({
        UserPoolId: awsconfig.aws_user_pools_id,
        Username: email,
        UserAttributes: [
            { Name: "email", Value: email },
            { Name: "name", Value: name },
            { Name: "email_verified", Value: "true" },
            ...Object.keys(additionalParams).map((key) => ({
                Name: key,
                Value: additionalParams[key],
            })),
        ],
        TemporaryPassword: temporaryPassword,
        MessageAction: "SUPPRESS",
    });

    try {
        const response = await client.send(command);

        return response;
    } catch (error) {
        console.error("Error creating user:", error);
        throw error;
    }
}

export async function deleteUser(username) {
    const command = new AdminDeleteUserCommand({
        UserPoolId: awsconfig.aws_user_pools_id,
        Username: username,
    });
    const response = await client.send(command);

    return response;
}
