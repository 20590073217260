import axios from "axios";
import {
    CardContent,
    Typography,
    useMediaQuery,
    Grid,
    TableContainer,
    Paper,
    CircularProgress,
    TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import CustomDataTable from "../../components/customs/table";
import { MainContent } from "./VotersList.styled";

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const PAGE_SIZE = 50;

const fetchOmegaCitizen = async (params) => {
    try {
        const response = await axios.get(
            "https://api-dev.omegadb.co/citizens",
            {
                params,
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        const { data } = response;

        return data;
    } catch (error) {
        throw new Error(error);
    }
};

const LoadingScreen = () => {
    <div
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
        }}
    >
        <CircularProgress />
    </div>;
};

const VotersList = () => {
    const { state } = useLocation();

    const {
        locationType,
        locationDetails: {
            provinceId,
            municipalityId,
            referenceId,
            barangayId,
            barangayName,
            municipalityName,
            districtName,
        },
    } = state;
    const [votersData, setVotersData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");

    const debouncedSearchQuery = useDebounce(searchQuery, 1000);

    const fetchVoters = async (search = "") => {
        setIsLoading(true);
        const municipalityFilter =
            locationType.value === "municipality" && municipalityId
                ? { municipality_id: municipalityId }
                : null;
        const districtFilter =
            locationType.value === "district" && referenceId
                ? { district_id: referenceId }
                : null;
        const barangayFilter =
            locationType.value === "barangay" && barangayId
                ? { barangay_id: barangayId }
                : null;
        const precinctFilter =
            locationType.value === "precinct" && referenceId
                ? { clustered_precinct_id: referenceId }
                : null;

        try {
            const data = await fetchOmegaCitizen({
                full_name: search,
                province_id: provinceId,
                page_size: PAGE_SIZE,
                page: currentPage,
                ...municipalityFilter,
                ...districtFilter,
                ...barangayFilter,
                ...precinctFilter,
            });
            setVotersData(data?.results);
            setTotalRecords(data?.count);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVoters(debouncedSearchQuery);
    }, [debouncedSearchQuery, currentPage]);

    const filterText = (text) => {
        return text.replace("AÃ", "");
    };

    const columns = useMemo(
        () => [
            {
                name: "Name",
                selector: (row) => filterText(row.full_name),
                sortable: true,
            },
            {
                name: "Precinct",
                selector: (row) => row.precinct?.name,
            },
            {
                name: "BPSF",
                selector: (row) =>
                    row.services.includes("BPSF") ? "✔️" : "❌",
                sortable: true,
            },
            {
                name: "Tingog",
                selector: (row) =>
                    row.services.includes("TINGOG") ? "✔️" : "❌",
                sortable: true,
            },
            {
                name: "AKAP",
                selector: (row) =>
                    row.services.includes("AKAP") ? "✔️" : "❌",
                sortable: true,
            },
        ],
        [],
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
        <MainContent>
            <CardContent
                style={{
                    marginTop: "110px",
                    padding: isMobile ? "0px" : "0px 150px",
                }}
            >
                <Grid
                    container
                    justifyContent="left"
                    alignItems="center"
                    sx={{ marginBottom: "1em", color: "#227445", gap: "60px" }}
                >
                    {municipalityName && (
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                            {"MUNICIPALITY: "}
                            {municipalityName}
                        </Typography>
                    )}
                    {barangayName && (
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                            {"BARANGAY: "}
                            {barangayName}
                        </Typography>
                    )}
                    {districtName && (
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                        >
                            {"DISTRICT: "}
                            {districtName}
                        </Typography>
                    )}
                </Grid>

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: "1em" }}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: "bold" }}
                    >
                        {"Voters List\r"}
                    </Typography>
                    <Grid item>
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ marginRight: "10px" }}
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <CustomDataTable
                        columns={columns}
                        data={votersData}
                        responsive
                        pagination
                        paginationServer
                        paginationTotalRows={totalRecords}
                        paginationPerPage={PAGE_SIZE}
                        onChangePage={handlePageChange}
                        progressPending={isLoading}
                    />
                </TableContainer>
            </CardContent>
            {isLoading && <LoadingScreen />}
        </MainContent>
    );
};

export default VotersList;
