export const POSITIONS = {
    president: 1,
    vicePresident: 2,
    senator: 3,
    partyList: 4,
    governor: 5,
    viceGovernor: 6,
    houseOfRepresentatives: 7,
    sanguniangPanlalawigan: 8,
    sanguniangPanlungsod: 9,
    sanguniangBayan: 10,
    mayor: 11,
    viceMayor: 12,
};

export const POSITIONS_OBJECT = [
    {
        label: "President",
        value: "president",
        id: 1,
    },
    {
        label: "Vice President",
        value: "vice-president",
        id: 2,
    },
    {
        label: "Senator",
        value: "senator",
        id: 3,
    },
    {
        label: "Partylist",
        value: "party list",
        id: 4,
    },
    {
        label: "Provincial Governor",
        value: "governor",
        id: 5,
    },
    {
        label: "Provincial Vice Governor",
        value: "vice-governor",
        id: 6,
    },
    {
        label: "Member, House of Representatives",
        value: "house-of-representatives",
        id: 7,
    },
    {
        label: "Municipal Mayor",
        value: "Mayor",
        id: 11,
    },
    {
        label: "Municipal Vice Mayor",
        value: "vice-mayor",
        id: 12,
    },
];

export const POSITIONS_OBJECT_WINNERS = [
    {
        label: "President",
        value: "president",
    },
    {
        label: "Vice President",
        value: "vice-president",
    },
    {
        label: "Senator",
        value: "senator",
    },
    {
        label: "Partylist",
        value: "party list",
    },
    {
        label: "Provincial Governor",
        value: "governor",
    },
    {
        label: "Provincial Vice Governor",
        value: "vice-governor",
    },
    {
        label: "Member, House of Representatives",
        value: "house-of-representatives",
    },

    {
        label: "Municipal Mayor",
        value: "mayor",
    },
    {
        label: "Municipal Vice-Mayor",
        value: "vice-mayor",
    },
];

// Get the value when it matches the string
export function getPositionByMatch(match) {
    return POSITIONS_OBJECT_WINNERS.find(
        (position) =>
            position.label.toLowerCase() === match.toLowerCase() ||
            position.value.toLowerCase() === match.toLowerCase(),
    );
}
