/* eslint-disable max-len */
import styled from "styled-components";

const NotFoundContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
    min-height: 90vh;
    color: white;
`;

const StyledTitle = styled.h1`
    font-size: 48px;
    font-weight: 400;
    @media (max-width: 1200px) {
        font-size: 28px;
    }
`;

const IconWrapper = styled.span`
    margin-top: 20px;
    font-size: 48px;
    margin-right: 8px;
    vertical-align: middle;
    @media (max-width: 1200px) {
        font-size: 28px;
    }
`;

const StyledParagraph = styled.p`
    font-size: 18px;
    margin-top: 1.4em;
    margin-bottom: 2.4em;
    font-weight: 500;
`;

export { NotFoundContainer, StyledTitle, StyledParagraph, IconWrapper };
