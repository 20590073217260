const APP_URL = process.env.REACT_APP_BASE_URL;
const REFRESH_KEY = process.env.REACT_APP_REFRESH_KEY;
const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;
const ID_KEY = process.env.REACT_APP_ID_KEY;
const API_X_AUTH = "END_KEY_X_";
const USER_KEY = "USER_DETAILS";

const getAccessToken = () => {
    return localStorage.getItem(SESSION_KEY);
};

const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_KEY);
};

const getIdToken = () => {
    return localStorage.getItem(ID_KEY);
};

const setAccessToken = (token) => {
    if (token) {
        localStorage.setItem(SESSION_KEY, token);
    }
};

const setIdToken = (token) => {
    if (token) {
        localStorage.setItem(ID_KEY, token);
    }
};

const setRefreshToken = (token) => {
    if (token) {
        localStorage.setItem(REFRESH_KEY, token);
    }
};

const getApiAccessToken = () => {
    return localStorage.getItem(API_X_AUTH);
};

const setApiAccessToken = (token) => {
    if (token) {
        localStorage.setItem(API_X_AUTH, token);
    }
};

const setUserDetails = (data) => {
    if (data) {
        localStorage.setItem(USER_KEY, JSON.stringify(data));
    }
};

const getUserDetails = () => {
    return JSON.parse(localStorage.getItem(USER_KEY) || null);
};

export {
    APP_URL,
    REFRESH_KEY,
    SESSION_KEY,
    ID_KEY,
    getAccessToken,
    setAccessToken,
    setRefreshToken,
    getRefreshToken,
    setUserDetails,
    getUserDetails,
    setIdToken,
    getIdToken,
    setApiAccessToken,
    getApiAccessToken,
};
