/* eslint-disable max-len */
import styled from "styled-components";

const UserAddContainer = styled.div`
    padding: 1.5rem;
    margin-top: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    color: #00695b;
    font-weight: 700;
`;

const StyledTitle = styled.h1`
    font-size: 48px;
    font-weight: 400;
    @media (max-width: 1200px) {
        font-size: 28px;
    }
`;

export { UserAddContainer, StyledTitle };
