import axios from "axios";
import { getAccessToken, APP_URL, getApiAccessToken } from "../configs/config";

export const getWinnability = async (candidateId, positionId, locationType) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/analysis/winnability`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                candidateId,
                positionId,
                locationType,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

export const getComparisonAnalysis = async (
    candidateId,
    candidateId2,
    locationType,
) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/analysis/compare`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                candidateId1: candidateId,
                candidateId2,
                locationType,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

export const getWinnersPerformance = async (positionId, locationType) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/analysis/performance`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                positionId,
                locationType,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

export const getTargetVotesCalculator = async (
    candidateId,
    positionId,
    locationType,
) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/analysis/calculator`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                candidateId,
                positionId,
                locationType,
            },
        });
        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};
