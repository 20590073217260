import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: transparent !important;
    box-shadow: none;
  }
`;

export default GlobalStyle;
