import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
    Button,
    TextField,
    Box,
    Typography,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { AppContext } from "../../context/AppContext";
import { APP_URL } from "../../configs/config";
import { ACCOUNT_TYPES } from "../../constants/accountTypes";

import {
    createUser,
    deleteUser,
    getUserDetailsByEmail,
    updateUserAttributes,
} from "../../api/usersApi";
import NotFound from "../not-found";

const accountTypesAvailable = ACCOUNT_TYPES;
const DEFAULT_PASSWORD = "!newUserPassword!00";

const UserEdit = () => {
    const { authToken, isUserMasterAdmin, userPrivileges } =
        useContext(AppContext);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedAwsUser, setSelectedAwsUser] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [accountTypes, setAccountTypes] = useState([]);
    const [userToDelete, setUserToDelete] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // DO NOT SHOW THE PAGE IF THE USER IS NOT MASTER ADMIN
    if (!isUserMasterAdmin) {
        return <NotFound />;
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${APP_URL}/users`, {
                headers: {
                    "x-auth-token": authToken,
                },
            });
            setUsers(response.data?.data);
        } catch (error) {
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        if (authToken) {
            fetchUsers();
        }
    }, [userPrivileges]);

    const handleDialogOpen = async (user = null) => {
        if (user) {
            setSelectedUser(user);
            setName(user.name);
            setEmail(user.email);
            setPhone(user.phone || "");
            setSelectedAwsUser(await getUserDetailsByEmail(user.email));

            try {
                setAccountTypes(user.accountTypes.map((type) => type.id));
            } catch (err) {
                setSnackbarMessage(`Errored: ${err}`);
                setSnackbarOpen(true);
            }
        } else {
            setSelectedUser(null);
            setName("");
            setEmail("");
            setAccountTypes([]);
        }
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSaveUser = async () => {
        const userPayload = {
            userId: selectedUser?.userId,
            name,
            email,
            accountTypes,
            phone,
        };

        if (email === "") {
            setSnackbarMessage("Errored: Email is required");
            setSnackbarOpen(true);

            return;
        }

        if (name === "") {
            setSnackbarMessage("Errored: Name is required");
            setSnackbarOpen(true);

            return;
        }

        if (phone === "") {
            setSnackbarMessage("Errored: Phone number is required");
            setSnackbarOpen(true);

            return;
        }

        if (accountTypes?.length === 0) {
            setSnackbarMessage("Errored: Please set an account type.");
            setSnackbarOpen(true);

            return;
        }

        try {
            if (selectedUser) {
                const attributes = { name, email };
                await updateUserAttributes(selectedUser?.userId, attributes);

                await axios.put(
                    `${APP_URL}/users/${selectedUser.id}`,
                    userPayload,
                    {
                        headers: {
                            "x-auth-token": authToken,
                        },
                    },
                );
            } else {
                const userData = await createUser(
                    email,
                    name,
                    DEFAULT_PASSWORD,
                );

                if (userData && userData?.User) {
                    await axios.post(
                        `${APP_URL}/users`,
                        {
                            ...userPayload,
                            userId: userData?.User?.Username,
                        },
                        {
                            headers: {
                                "x-auth-token": authToken,
                            },
                        },
                    );
                }
            }
            fetchUsers();
            setDialogOpen(false);
        } catch (error) {
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        }
    };

    const handleDeleteUser = async () => {
        try {
            const user = users.find((u) => u.id === userToDelete);

            if (user) {
                await deleteUser(user.userId);
            }

            await axios.delete(`${APP_URL}/users/${userToDelete}`, {
                headers: {
                    "x-auth-token": authToken,
                },
            });
            fetchUsers();
            setDeleteDialogOpen(false);
        } catch (error) {
            setSnackbarMessage(`Errored: ${error}`);
            setSnackbarOpen(true);
        }
    };

    const handleDeleteDialogOpen = (id) => {
        setUserToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setUserToDelete(null);
    };

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 13 }}>
            <Box
                sx={{
                    minHeight: "85vh",
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 3,
                    position: "relative",
                }}
            >
                <Typography component="h1" variant="h5">
                    {"User Management"}
                </Typography>
                <Box
                    sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDialogOpen()}
                    >
                        {"Add User"}
                    </Button>
                </Box>
                <Box sx={{ mt: 3, width: "100%" }}>
                    {users &&
                        users.map((user) => (
                            <Box
                                key={user.id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 2,
                                    p: 2,
                                    border: "1px solid #ccc",
                                    borderRadius: 1,
                                    backgroundColor: "white",
                                }}
                            >
                                <Typography
                                    sx={{
                                        minWidth: "200px",
                                    }}
                                >
                                    {user.name}
                                </Typography>
                                <Typography>{user.email}</Typography>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleDialogOpen(user)}
                                        sx={{ mr: 1 }}
                                    >
                                        {"Edit"}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() =>
                                            handleDeleteDialogOpen(user.id)
                                        }
                                    >
                                        {"Delete"}
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    {selectedUser ? "Edit User" : "Add User"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedUser
                            ? "Modify the user details below."
                            : "Enter the details of the new user."}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="nameInput"
                        label="Name"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="emailInput"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!selectedUser && (
                        <TextField
                            aria-readonly="true"
                            margin="dense"
                            id="defaultPassword"
                            label="Default Password"
                            type="text"
                            fullWidth
                            value={DEFAULT_PASSWORD}
                        />
                    )}
                    <TextField
                        margin="dense"
                        id="phoneInput"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="accountTypes-label">
                            {"Account Types"}
                        </InputLabel>
                        <Select
                            labelId="accountTypes-label"
                            id="accountTypes"
                            multiple
                            value={accountTypes}
                            onChange={(e) => setAccountTypes(e.target.value)}
                            renderValue={(selected) =>
                                selected
                                    .map(
                                        (id) =>
                                            accountTypesAvailable.find(
                                                (type) => type.id === id,
                                            )?.name,
                                    )
                                    .join(", ")
                            }
                        >
                            {accountTypesAvailable.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{"Cancel"}</Button>
                    <Button
                        onClick={handleSaveUser}
                        variant="contained"
                        color="primary"
                    >
                        {"Save"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>{"Delete User"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Are you sure you want to delete this user?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>
                        {"Cancel"}
                    </Button>
                    <Button
                        onClick={handleDeleteUser}
                        variant="contained"
                        color="error"
                    >
                        {"Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="error"
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Container>
    );
};

export default UserEdit;
