/* eslint-disable max-len */
import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

const WrapperMid = styled.div`
    padding: 1.5rem;

    border-radius: 12px;
    display: flex;
    gap: 1rem;
    color: #00695b;
    font-weight: 700;
    border-radius: 12px;

    .table-responsive {
        align-items: "flex-end";
        justify-content: "flex-end";
        margin-top: 1rem;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const TextTitle = styled(Box)`
    font-family: "Open Sans";
    color: "#000000";
    font-size: 32px;
`;

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiAutocomplete-option": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiFormLabel-root": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Open Sans, sans-serif",
    },
});

const DropdownStyledTop = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    padding-top: 100px;
    background: white;
    min-height: 100vh;
    background-image: linear-gradient(66deg, white 24%, #5ad990 200%);
    overflow: auto;
`;

const DropdownStyledMid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 100px;
    background: white;
    min-height: 100vh;
`;

export {
    DropdownStyledTop,
    WrapperMid,
    DropdownStyledMid,
    StyledAutocomplete,
    TextTitle,
    HeaderContainer,
};
