/* eslint-disable no-console */
import axios from "axios";
import { getAccessToken, APP_URL, getApiAccessToken } from "../configs/config";

const regions = async () => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/region`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const provinces = async (regionId) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/province`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                regionId,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const districts = async (region, province) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/district`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                region,
                province,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const municipalities = async () => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/municipality`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const municipalitiesDistrict = async (provinceId, districtId) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/municipality/v2`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            params: {
                provinceId,
                districtId,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const barangays = async ({
    regionId,
    provinceId,
    districtId,
    municipalityId,
}) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/barangay`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            // parameters are on "OR" operation, which means you only have to supply one or more
            params: {
                regionId,
                provinceId,
                districtId,
                municipalityId,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const clusteredPrecincts = async ({
    regionId,
    provinceId,
    districtId,
    municipalityId,
    barangayId,
}) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/clustered-precinct`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            // parameters are on "OR" operation, which means you only have to supply one or more
            params: {
                regionId,
                provinceId,
                districtId,
                municipalityId,
                barangayId,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

const precincts = async ({
    regionId,
    provinceId,
    districtId,
    municipalityId,
    barangayId,
    clusteredPrecinctId,
}) => {
    const token = getAccessToken();
    const authToken = getApiAccessToken();

    try {
        const response = await axios.get(`${APP_URL}/precinct`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-auth-token": authToken,
            },
            // parameters are on "OR" operation, which means you only have to supply one or more
            params: {
                regionId,
                provinceId,
                districtId,
                municipalityId,
                barangayId,
                clusteredPrecinctId,
            },
        });

        const { data } = response;

        return data;
    } catch (error) {
        console.error(error);

        return [];
    }
};

export {
    regions,
    provinces,
    districts,
    municipalities,
    municipalitiesDistrict,
    barangays,
    clusteredPrecincts,
    precincts,
};
