/* eslint-disable react/forbid-prop-types */

import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

createTheme(
    "custom",
    {
        background: {
            default: "#F6F6F6",
        },
    },
    "light",
);

const CustomDataTable = ({
    data = [],
    columns = [],
    fixedHeader,
    noHeader,
    pagination,
    progressPending,
    paginationPerPage,
    onChangePage,
    onChangeRowsPerPage,
    paginationServer,
    paginationTotalRows,
    subHeader,
    subHeaderComponent,
    progressComponent,
    // defaultSortAsc,
    // defaultSortField,
    // onSort,
    sortServer,
    height = "auto",
    onRowClicked,
    rowStyle = () => ({}),
    theme,
}) => {
    const customStyles = {
        noData: {
            style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem 0rem",
            },
        },
        table: {
            style: {
                border: "6px solid #ADD2C7",
                borderRadius: "6px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#ADD2C7",
            },
        },
        headCells: {
            style: {
                fontSize: "14px",
                fontWeight: "bold",
            },
        },
    };

    const dataTableOptions = {
        className: "dataTable-custom",
        customStyles,
        data,
        columns,
        pagination: true,
        highlightOnHover: true,
        persistTableHead: true,
        progressComponent: progressComponent || (
            <div className="text-center py-1">
                <Spinner animation="border" color="light" size="sm" />
            </div>
        ),
    };

    if (fixedHeader !== undefined) {
        dataTableOptions.fixedHeader = fixedHeader;
    }
    if (noHeader !== undefined) dataTableOptions.noHeader = noHeader;
    if (pagination !== undefined) dataTableOptions.pagination = pagination;

    if (progressPending !== undefined) {
        dataTableOptions.progressPending = progressPending;
    }

    if (paginationPerPage !== undefined) {
        dataTableOptions.paginationPerPage = paginationPerPage;
    }

    if (onChangePage !== undefined) {
        dataTableOptions.onChangePage = onChangePage;
    }

    if (onChangeRowsPerPage !== undefined) {
        dataTableOptions.onChangeRowsPerPage = onChangeRowsPerPage;
    }

    if (paginationServer !== undefined) {
        dataTableOptions.paginationServer = paginationServer;
    }

    if (paginationTotalRows !== undefined) {
        dataTableOptions.paginationTotalRows = paginationTotalRows;
    }
    if (subHeader !== undefined) dataTableOptions.subHeader = subHeader;

    if (subHeaderComponent !== undefined) {
        dataTableOptions.subHeaderComponent = subHeaderComponent;
    }
    // if (onSort !== undefined) dataTableOptions.onSort = onSort;
    // if (sortServer !== undefined) dataTableOptions.sortServer = sortServer;

    // if (defaultSortField !== undefined) {
    //     dataTableOptions.defaultSortField = defaultSortField;
    // }

    // if (defaultSortAsc !== undefined) {
    //     dataTableOptions.defaultSortAsc =
    //         `${defaultSortAsc}`?.toLowerCase() === "asc";
    // }

    return (
        <DataTable
            /* eslint-disable react/jsx-props-no-spreading */
            {...dataTableOptions}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            fixedHeader
            fixedHeaderScrollHeight={height}
            onRowClicked={onRowClicked}
            theme={theme}
            conditionalRowStyles={[
                {
                    when: (row) => true,
                    style: (row) => rowStyle(row),
                },
            ]}
        />
    );
};

CustomDataTable.propTypes = {
    onChangePage: PropTypes.oneOfType([PropTypes.func]),
    data: PropTypes.array,
    columns: PropTypes.array,
    fixedHeader: PropTypes.bool,
    noHeader: PropTypes.bool,
    pagination: PropTypes.bool,
    progressPending: PropTypes.bool,
    paginationPerPage: PropTypes.number,
    onChangeRowsPerPage: PropTypes.func,
    paginationServer: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    paginationTotalRows: PropTypes.number,
    subHeader: PropTypes.bool,
    subHeaderComponent: PropTypes.oneOfType([PropTypes.element]),
    progressComponent: PropTypes.element,
    defaultSortAsc: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    defaultSortField: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onSort: PropTypes.func,
    sortServer: PropTypes.bool,
    height: PropTypes.string,
    onRowClicked: PropTypes.func,
    theme: PropTypes.string,
};

CustomDataTable.defaultProps = {
    pagination: true,
    progressPending: false,
    paginationPerPage: 10,
    paginationServer: null,
    paginationTotalRows: null,
    onChangeRowsPerPage: () => null,
    onSort: () => null,
    onChangePage: () => null,
    onRowClicked: () => null,
    subHeader: false,
    theme: "light",
    data: [],
    height: "auto",
    sortServer: true,
    columns: [],
    fixedHeader: false,
    noHeader: false,
    defaultSortField: "",
    progressComponent: null,
    subHeaderComponent: null,
    defaultSortAsc: "",
};

export default CustomDataTable;
