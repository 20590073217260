import React from "react";
import { Auth } from "aws-amplify";
import {
    ThemeProvider as MuiThemeProvider,
    CssBaseline,
} from "@mui/material/styles";
import { ThemeProvider } from "styled-components";

import awsconfig from "./configs/aws-config";
import AppRouter from "./routes/Router";
import GlobalStyle from "./assets/styles/GlobalStyles";
import Theme from "./assets/theme/Theme";
import AppProvider from "./context/AppContext";

const App = () => {
    Auth.configure(awsconfig);

    return (
        <MuiThemeProvider theme={Theme}>
            <ThemeProvider theme={Theme}>
                <AppProvider>
                    <GlobalStyle />
                    <AppRouter />
                </AppProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default App;
