import React from "react";

import { UserAddContainer, StyledTitle } from "./UserAdd.styled";

const UserAdd = () => {
    return (
        <UserAddContainer>
            <StyledTitle>{"Add User"}</StyledTitle>
        </UserAddContainer>
    );
};

export default UserAdd;
