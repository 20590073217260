import AWS from "aws-sdk";

const awsconfig = {
    aws_project_region: process.env.REACT_APP_REGION,
    aws_cognito_region: process.env.REACT_APP_REGION,
    aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    aws_storage_bucket: process.env.REACT_APP_S3_BUCKET,
    aws_storage_region: process.env.REACT_APP_REGION,
    aws_access_key_id: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    aws_secret_access_key: process.env.REACT_APP_AWS_ACCESS_KEY,
};

AWS.config.update({
    accessKeyId: awsconfig.aws_access_key_id,
    secretAccessKey: awsconfig.aws_secret_access_key,
});

export default awsconfig;
