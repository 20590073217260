import React, { useState, createContext, useMemo, useEffect } from "react";
import axios from "axios";
import { APP_URL, getUserDetails } from "../configs/config";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState(false);
    const [cognitoUser, setCognitoUser] = useState({});
    const [candidate, setCandidate] = useState("");
    const [candidate2, setCandidate2] = useState("");
    const [position, setPosition] = useState("");
    const [positionId, setPositionId] = useState("");
    const [location, setLocation] = useState("");

    const [authToken, setAuthToken] = useState("");
    const [userPrivileges, setUserPrivileges] = useState([]);
    const [isUserMasterAdmin, setIsUserMasterAdmin] = useState(false);
    const [isNationalAccount, setIsNationalAccount] = useState(false);
    const [isProvinceAccount, setIsProvinceAccount] = useState(false);
    const [isDistrictAccount, setIsDistrictAccount] = useState(false);
    const [isMunicipalityAccount, setIsMunicipalityAccount] = useState(false);

    const userDetails = getUserDetails();

    useEffect(() => {
        const fetchAuthToken = async () => {
            if (userDetails && userDetails.username) {
                try {
                    const response = await axios.post(
                        `${APP_URL}/token/generate`,
                        { userId: userDetails.username },
                    );
                    setAuthToken(response?.data?.token);
                    setUserPrivileges(response?.data?.data?.accountTypes);
                } catch (error) {
                    console.error("Error fetching auth token:", error);
                }
            } else {
                console.error(
                    "User details are null or username is not defined",
                );
            }
        };

        fetchAuthToken();
    }, []);

    useEffect(() => {
        setIsUserMasterAdmin(
            userPrivileges.some((type) => type.name === "Master Admin"),
        );

        setIsNationalAccount(
            userPrivileges.some((type) => type.name === "National Account"),
        );

        setIsProvinceAccount(
            userPrivileges.some((type) => type.name === "Province Account"),
        );

        setIsDistrictAccount(
            userPrivileges.some((type) => type.name === "District Account"),
        );

        setIsMunicipalityAccount(
            userPrivileges.some((type) => type.name === "Municipality Account"),
        );
    }, [userPrivileges]);

    const payload = {
        loggedInUser,
        setLoggedInUser,

        isUserMasterAdmin,
        isNationalAccount,
        isProvinceAccount,
        isDistrictAccount,
        isMunicipalityAccount,

        cognitoUser,
        setCognitoUser,
        candidate,
        setCandidate,
        candidate2,
        setCandidate2,
        position,
        setPosition,
        positionId,
        setPositionId,
        location,
        setLocation,
        authToken,
    };

    return useMemo(
        () => (
            <AppContext.Provider value={payload}>
                {children}
            </AppContext.Provider>
        ),
        [payload],
    );
};

export default AppProvider;
