/* eslint-disable max-len */
import styled, { css } from "styled-components";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiAutocomplete-option": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiFormLabel-root": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Open Sans, sans-serif",
    },
    "& .MuiAutocomplete-listbox": {
        maxHeight: "200px", // Adjust the height as needed
        overflowY: "auto", // Ensure overflow is handled
    },
});

const WrapperTop = styled("div")(({ theme }) => ({
    display: "flex",
    gap: "1rem",
    overflowY: "auto",
    fontFamily: "Open Sans, sans-serif",

    "& .candidate-options, & .position-options, & .location-options-default": {
        width: "100%",
        maxHeight: "200px",

        overflowY: "hidden",
        marginRight: ".5rem",
    },

    "& .candidateDropdown, & .postionDropdown, & .locationDropdown": {
        maxHeight: "200px",

        overflowY: "hidden",
    },

    [theme.breakpoints.down("sm")]: {
        "& .candidate-options, & .position-options, & .location-options-default":
            {
                width: "200px",
            },
        "& .candidateDropdown, & .postionDropdown, & .locationDropdown": {
            maxHeight: "70px",
        },
        "& .position-options-winners-performance, & .location-options-winners-performance":
            {
                width: "40rem",
            },
    },

    [theme.breakpoints.up("md")]: {
        "& .candidate-options": {
            width: "18rem",
        },
        "& .position-options, & .location-options-default": {
            width: "15rem",
        },
        "& .candidateDropdown, & .postionDropdown, & .locationDropdown": {
            maxHeight: "70px",
        },
        "& .position-options-winners-performance, & .location-options-winners-performance":
            {
                width: "15rem",
            },
    },
    [theme.breakpoints.up("lg")]: {
        "& .candidate-options": {
            width: "20rem",
        },
        "& .position-options, & .location-options-default": {
            width: "20rem",
        },
        "& .candidateDropdown, & .postionDropdown, & .locationDropdown": {
            maxHeight: "70px",
        },
        "& .position-options-winners-performance, & .location-options-winners-performance":
            {
                width: "20rem",
            },
    },

    [theme.breakpoints.up("xl")]: {
        "& .candidate-options": {
            width: "27rem",
        },
        " & .position-options, & .location-options-default": {
            width: "28rem",
        },
        "& .candidateDropdown, & .postionDropdown, & .locationDropdown": {
            maxHeight: "70px",
        },
        "& .position-options-winners-performance, & .location-options-winners-performance":
            {
                width: "28rem",
            },
    },
}));

const Backdiv = styled("div")(({ theme }) => ({
    fontFamily: "Open Sans, sans-serif",

    ".MuiIconButton-edgeEnd": {
        height: "1rem",
        fontSize: "1rem",
    },

    [theme.breakpoints.up("md")]: {
        ".MuiIconButton-edgeEnd": {
            fontSize: "1rem",
        },
    },

    [theme.breakpoints.up("lg")]: {
        ".MuiIconButton-edgeEnd": {
            fontSize: "1rem",
        },
    },

    [theme.breakpoints.up("xl")]: {
        ".MuiIconButton-edgeEnd": {
            fontSize: "1rem",
        },
    },
}));

const StyledTab = styled(Tab)`
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export { WrapperTop, StyledTab, Backdiv, StyledAutocomplete };
