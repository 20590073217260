import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
    palette: {
        primary: {
            main: "#0E3B26",
            light: "#00695B",
        },
        secondary: {
            main: "#76B087",
            light: "#C6E2C9",
        },
        otherColor: {
            main: "#E9F6EC",
        },
        typography: {
            allVariants: {
                fontFamily: "Open Sans, sans-serif", // Set Open Sans as the default font
            },
        },
    },
});

export default Theme;
