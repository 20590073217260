/* eslint-disable max-len */
import styled from "styled-components";

const WrapperMid = styled.div`
    padding: 1.5rem;
    margin-top: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    color: #00695b;
    font-weight: 700;
`;

const DropdownStyledTop = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 0px;
    padding-top: 100px;
    background: white;
    background-image: linear-gradient(66deg, white 24%, #5ad990 200%);
    min-height: 100vh;
`;

const DropdownStyledMid = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 0px;
    padding-top: 100px;
    background: white;
    min-height: 100vh;
`;

export { DropdownStyledTop, WrapperMid, DropdownStyledMid };
